import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';

import towerImg from './images/tower.png';
import css from './SectionFeaturesSingleBloclStyle1.module.css';

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionFeaturesSingleBloclStyle1 = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <div className={classNames(defaultClasses.sectionDetails, css.root)}>
          <div className={css.contentWrapper}>
            <div className={css.leftSide}>
              <Field data={title} className={defaultClasses.title} options={fieldOptions} />
              <Field
                data={description}
                className={defaultClasses.description}
                options={fieldOptions}
              />
            </div>
            <div className={css.centerSide}>
              <img src={towerImg} alt="tower" title="tower" />
            </div>
            <div className={css.rightSide}>
              <Field
                data={callToAction}
                className={defaultClasses.ctaButton}
                options={fieldOptions}
              />
            </div>
          </div>
        </div>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFeaturesSingleBloclStyle1.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionFeaturesSingleBloclStyle1.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionFeaturesSingleBloclStyle1;
