import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import loadable from '@loadable/component';

import SectionFeaturesDefault from './SectionFeaturesDefault';
import SectionFeaturesWithHeroBlock from './SectionFeaturesSingleBloclStyle1';
import SectionFeaturesFaqSection from './SectionFeaturesFaqSection';

const SectionFeaturesWithSearch = loadable(() =>
  import(/* webpackChunkName: "SectionFeaturesWithSearch" */ './SectionFeaturesWithSearch')
);

// Section component that's able to show blocks in multiple different columns (defined by "numColumns" prop)
const SectionFeaturesCustom = props => {
  const { sectionName } = props;

  if (/^hero-with-search/.test(sectionName)) {
    return <SectionFeaturesWithSearch {...props} />;
  }

  if (/^single-block-style1/.test(sectionName)) {
    return <SectionFeaturesWithHeroBlock {...props} />;
  }

  if (/^faq-section/.test(sectionName)) {
    return <SectionFeaturesFaqSection {...props} />;
  }

  return <SectionFeaturesDefault {...props} />;
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFeaturesCustom.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionFeaturesCustom.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionFeaturesCustom;
