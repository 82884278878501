import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import SectionContainer from '../SectionContainer';

import css from './SectionFeaturesFaqSection.module.css';
import classNames from 'classnames';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState('0px');
  const answerRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setHeight(`${answerRef.current.scrollHeight}px`);
    } else {
      setHeight('0px');
    }
  }, [isOpen]);

  return (
    <div className={css.faqItem}>
      <div className={css.question} onClick={toggleOpen}>
        {question}
        <FontAwesomeIcon
          className={classNames(css.icon, isOpen ? css.answerOpenIcon : '')}
          icon={faChevronDown}
        />
      </div>
      <div className={css['answer-wrapper']} style={{ height }} ref={answerRef}>
        <div className={css['answer-inner-wrapper']}>
        <ReactMarkdown>{answer}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionFeaturesFaqSection = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
  } = props;

  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const faqData = props?.blocks?.map(data => ({
    question: data.title.content,
    answer: data.text.content,
  }));

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <h2 className={css.heading}>{title?.content}</h2>
      <p className={css.subheading}>{description?.content}</p>
      <div className={css.questionsContainer}>
        <div className={css.questionsSegment}>
          {faqData.map((item, index) => (
            <FAQItem
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFeaturesFaqSection.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionFeaturesFaqSection.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionFeaturesFaqSection;
